
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Pill = ({ text, classNames }) => (
  <div className={`pill ${classNames ? ` ${classNames}` : ''}`}>
    {text}
  </div>
);

Pill.propTypes = {
  classNames: PropTypes.string,
  text: PropTypes.string
};

Pill.defaultProps = {
  classNames: null,
  text: null
};

export default Pill;
