import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import Button from '@youship/components/objects/button';
import OrderTypeCard from 'components/order-type-card';

import './styles.scss';

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const decorImage = 'categories/decor.png';
const electronicsImage = 'categories/electronics.png';
const sportsImage = 'categories/sports.png';
const giftsImage = 'categories/gifts.png';
const pharmaciesImage = 'categories/pharmacies.png';
const flowersImage = 'categories/flowers.png';
const servicesImage = 'categories/services.png';
const storesImage = 'categories/stores.png';
const photocopiesImage = 'categories/photocopies.jpg';
const bookstoresImage = 'categories/bookstores.jpg';
const dressmakersImage = 'categories/dressmakers.jpg';
const restaurantsImage = 'categories/restaurants.jpg';

//const furnitureImage = 'categories/furniture.png';
//const bakeriesImage = 'categories/bakeries.jpg';

const CAT_TENDENCIES = [
  {
    id: "decor",
    name: 'home.tendencies.swiper.decor.title',
    description: 'home.tendencies.swiper.decor.description',
    imageUrl: decorImage
  },
  {
    id: "electronics",
    name: 'home.tendencies.swiper.electronics.title',
    description: 'home.tendencies.swiper.electronics.description',
    imageUrl: electronicsImage
  },
  {
    id: "sports",
    name: 'home.tendencies.swiper.sports.title',
    description: 'home.tendencies.swiper.sports.description',
    imageUrl: sportsImage
  },
  {
    id: "gifts",
    name: 'home.tendencies.swiper.gifts.title',
    description: 'home.tendencies.swiper.gifts.description',
    imageUrl: giftsImage
  },
  {
    id: "pharmacies",
    name: 'home.tendencies.swiper.pharmacies.title',
    description: 'home.tendencies.swiper.pharmacies.description',
    imageUrl: pharmaciesImage
  },
  {
    id: "flowers",
    name: 'home.tendencies.swiper.flowers.title',
    description: 'home.tendencies.swiper.flowers.description',
    imageUrl: flowersImage
  },
  {
    id: "services",
    name: 'home.tendencies.swiper.services.title',
    description: 'home.tendencies.swiper.services.description',
    imageUrl: servicesImage
  },
  {
    id: "stores",
    name: 'home.tendencies.swiper.stores.title',
    description: 'home.tendencies.swiper.stores.description',
    imageUrl: storesImage
  },
  {
    id: "photocopies",
    name: 'home.tendencies.swiper.photocopies.title',
    description: 'home.tendencies.swiper.photocopies.description',
    imageUrl: photocopiesImage
  },
  {
    id: "bookstores",
    name: 'home.tendencies.swiper.bookstores.title',
    description: 'home.tendencies.swiper.bookstores.description',
    imageUrl: bookstoresImage
  },
  {
    id: "dressmakers",
    name: 'home.tendencies.swiper.dressmakers.title',
    description: 'home.tendencies.swiper.dressmakers.description',
    imageUrl: dressmakersImage
  },
  {
    id: "restaurants",
    name: 'home.tendencies.swiper.restaurants.title',
    description: 'home.tendencies.swiper.restaurants.description',
    imageUrl: restaurantsImage
  }
];


const HomeTendencies = () => {
  const intl = useIntl();

  return (
    <div className="home-tendencies">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-6 col-xl-4">
            <h6>
              <FormattedMessage id="home.tendencies.title" />
            </h6>
            <h2 className="h2--display home-tendencies__description">
              <FormattedMessage id="home.tendencies.description" />
            </h2>
          </div>
          <div className="col col-12 col-md-6 col-xl-8 d-flex justify-content-md-end align-items-md-end">
            <Button
              context="primary"
              external
              linkProps={{ to: WEB_APP_URL }}
              noBackground
              noNewTab
              noPadding
              noShadow
              small
            >
              <FormattedMessage id="home.tendencies.link" />
            </Button>
          </div>
        </div>
        <Swiper
          breakpoints={{
            992: {
              spaceBetween: 32
            }
          }}
          className="home-tendencies__slider"
          slidesPerView="auto"
          spaceBetween={16}
        >
            {CAT_TENDENCIES.map(cat => (
              <SwiperSlide className="home-tendencies__slider-slide">
                <OrderTypeCard
                  classNames="home-tendencies__slider-card"
                  description={intl.formatMessage({ id: cat.description })}
                  id={cat.id}
                  imageUrl={cat.imageUrl}
                  name={intl.formatMessage({ id: cat.name })}
                  noMargin
                />
            </SwiperSlide>
            ))}

         {/* <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.restaurants.description' })}
              id="food"
              imageUrl={restaurantsImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.restaurants.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.bookstores.description' })}
              id="bookstores"
              imageUrl={bookstoresImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.bookstores.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.photocopies.description' })}
              id="photocopies"
              imageUrl={photocopiesImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.photocopies.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.dressmakers.description' })}
              id="dressmakers"
              imageUrl={dressmakersImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.dressmakers.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.bakeries.description' })}
              id="bakeries"
              imageUrl={bakeriesImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.bakeries.title' })}
              noMargin
            />
          </SwiperSlide> */}

        </Swiper>
      </div>
    </div>
  );
};

export default injectIntl(HomeTendencies);
