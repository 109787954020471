import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import HomeApp from 'components/sections/home-app';
import HomeBenefits from 'components/sections/home-benefits';
import HomeConcierge from 'components/sections/home-concierge';
import HomeGrow from 'components/sections/home-grow';
import HomeHeader from 'components/sections/home-header';
import HomeTendencies from 'components/sections/home-tendencies';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Solution from 'components/sections/solution';
import Features from 'components/sections/features';

//import bannerImage from 'images/create-account.png';
const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'works.banner.buttons.contacts'
  }
];


const IndexPage = ({ pageContext: { locale } }) => {
 
  return (
    <Layout locale={locale}>
      <SEO title="home.title" />
      <HomeHeader />
      <Solution />
      <Features />
      <Banner
        buttons={bannerButtons}
        image={bannerImage}
        title="home.banner.title"
      />
     {/*  <Banner
        image="bannerImage"
        text="home.banner.text"
        title="home.banner.title"
        {...bannerProps}
      /> */}
    </Layout>
  );
};

export default IndexPage;
