import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Img from 'components/image';

import './styles.scss';

const StepsList = ({ classNames, steps }) => (
  <div className={`steps-list ${classNames ? ` ${classNames}` : ''}`}>
    <div className="row justify-content-center">
      {Array.isArray(steps) && steps.map((step, index) => (
        <div
          key={index}
          className="col col-12 col-md-4"
        >
          <div className={`steps-list__step steps-list__step--margin ${step.classname ? ` ${step.classname}` : ''}`}>
            {step.image && (
              <div className="steps-list__step-image-wrapper">
                { step.image && typeof step.image === 'string' && step.image.includes(".inline.svg") === false  ? (
                  <Img
                    alt={step.title}
                    className="steps-list__step-image"
                    src={step.image}
                  />
                ) : (
                  <step.image />
                )}
              </div>
            )}
            {step.title && (
              <div className="steps-list__step-title">
                <FormattedMessage id={step.title} />
              </div>
            )}
            {step.description && (
              <div className="steps-list__step-description">
                <FormattedMessage id={step.description} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

StepsList.propTypes = {
  classNames: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }))
};

StepsList.defaultProps = {
  classNames: null,
  steps: null
};

export default StepsList;
