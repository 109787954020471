import React from 'react';
import { FormattedMessage} from 'react-intl';

import  simg1 from 'images/solution/person.inline.svg';
import  simg2 from 'images/solution/house.inline.svg';
import  simg3 from 'images/solution/boxes.inline.svg';
import  simg4 from 'images/solution/broadcast.inline.svg';
import  simg5 from 'images/solution/app.inline.svg';

import StepsList from 'components/steps-list';

import './styles.scss';

const className = "steps-list__step-rounded2";

const followImage = 'solution/robot.png';

/*
const simg1 = 'solution/person.inline.svg';
const simg2 = 'solution/house.inline.svg';
const simg3 = 'solution/boxes.inline.svg';
const simg4 = 'solution/broadcast.inline.svg';
const simg5 = 'solution/app.inline.svg';
*/

const STEPS = [
  {
    index: 1,
    title: 'home.features.s1.title',
    description: 'home.features.s1.description',
    image: simg1,
    classname: className
  },
  {
    index: 2,
    image: followImage
  },
  {
    index: 3,
    title: 'home.features.s2.title',
    description: 'home.features.s2.description',
    image: simg2,
    classname: className
  },
  {
    index: 4,
    title: 'home.features.s3.title',
    description: 'home.features.s3.description',
    image: simg3,
    classname: className
  },
  {
    index: 5,
    title: 'home.features.s4.title',
    description: 'home.features.s4.description',
    image: simg4,
    classname: className
  },
  {
    index: 6,
    title: 'home.features.s5.title',
    description: 'home.features.s5.description',
    image: simg5,
    classname: className
  }
];

const Features = () => (
  <div className="features">
    <div className="container">
      <h2 className="h2--display features__description">
        <FormattedMessage id="home.features.title" />
      </h2>
      <StepsList
        steps={STEPS}
      />

    </div>
  </div>
);

export default Features;
