import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/image';

import SharedOrderTypeCard from '@youship/components/order-type-card';

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const OrderTypeCard = ({ id, ...props }) => (
  id || typeof id === 'number' ? (
    <SharedOrderTypeCard
      {...props}
      imgComponent={Img}
      // linkProps={{ to: `${WEB_APP_URL}/category/${id}` }}
      linkProps={{ to: `${WEB_APP_URL}` }}
    />
  ) : (
    <SharedOrderTypeCard {...props} />
  )
);


OrderTypeCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

OrderTypeCard.defaultProps = {
  id: null
};

export default OrderTypeCard;
