import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from 'utils/localized-link';

import BoxIcon from 'images/icons/box.inline.svg';
import StarIcon from 'images/icons/star.inline.svg';
import TrophyIcon from 'images/icons/trophy.inline.svg';
import robotImg from 'images/home/robot.png';

import Button from '@youship/components/objects/button';
import Diagram from 'components/diagram';

import './styles.scss';

const BENEFITS = [
  {
    title: 'home.benefits.b1.title',
    description: 'home.benefits.b1.description',
    icon: TrophyIcon
  },
  {
    title: 'home.benefits.b2.title',
    description: 'home.benefits.b2.description',
    icon: StarIcon
  },
  {
    title: 'home.benefits.b3.title',
    description: 'home.benefits.b3.description',
    icon: BoxIcon
  }
];

const HomeConcierge = () => (
  <div className="home-concierge">
    <div className="container">
      <div className="row d-flex">
        <div className="col col-12 col-md-6 col-xl-5">
          <h2 className="h2--display home-concierge__highlight">
            <FormattedMessage id="home.concierge.title" />
          </h2>
          <h2 className="h2--display home-concierge__description">
            <FormattedMessage id="home.concierge.description" />
          </h2>
          <Button
            context="primary"
            linkComponent={Link}
            linkProps={{ to: '/robot' }}
            noBackground
            noPadding
            noShadow
            small
          >
            <FormattedMessage id="home.concierge.link" />
          </Button>
        </div> 
        <div className="col col-12 col-md-6 col-lg-5 offset-lg-1">
          <img
              alt="Robot"
              className="home-concierge__image"
              src={robotImg}
            />
        </div>
      </div>
      <div class="home-concierge__separator"></div>
    </div>
    
  </div>
);

export default HomeConcierge;
