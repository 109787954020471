import React, { useEffect, useState }  from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';
//import video1 from 'images/robot/robot.mp4';
import video1 from 'images/home/robot-delivery-site.mp4';

import './styles.scss';

//const headerImage1 = 'home/header_image_1.jpg';
//const headerImage1 = 'home/bicycle.png';
//const headerImage2 = 'home/header_image_2.jpg';

const videoPreview = 'home/video-bg.jpg';

const numMsgs = 6;

const HomeHeader = ({ intl }) => { 

  const [curDiv, setCurDiv] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if(curDiv >= numMsgs ){
        setCurDiv(1);
      }else{
        setCurDiv(curDiv+1);
      }  
    }, 3000);
    return () => clearInterval(interval);
  }, [curDiv]);

  return (
  <div className="home-header">
    <div className="">
        <video autoplay="true" loop="true" muted="true"
            playsinline="" data-wf-ignore="true" data-object-fit="cover"
            poster={videoPreview} 
            className="home-header__video">
            <source src={video1} />
        </video>
        
        <div className="container">
          <div className="row d-flex">
            <div className="col col-12 col-md-8 offset-md-1 order-md-3">
                <div className="home-header__text">
                  <h1 className="h1--display home-header__title">
                    <FormattedMessage id="home.header.title" />
                  </h1>
                  <div className="home-header__description">
                    <FormattedMessage id={`home.header.b${curDiv}`} />
                  </div>
                </div>
              </div>
            </div>
        </div>

    </div>
  </div>
  );
};

export default injectIntl(HomeHeader);
