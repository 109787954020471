import React from 'react';
import { FormattedMessage} from 'react-intl';

import StepsList from 'components/steps-list';

import './styles.scss';

const followImage = 'solution/follow-me.png';

const className = "steps-list__step-rounded";

const STEPS = [
  {
    index: 1,
    title: 'home.solution.s1.title',
    description: 'home.solution.s1.description',
    classname: className
  },
  {
    index: 3,
    title: 'home.solution.s2.title',
    description: 'home.solution.s2.description',
    classname: className
  },
  {
    index: 4,
    title: 'home.solution.s3.title',
    description: 'home.solution.s3.description',
    classname: className
  },
  {
    index: 5,
    title: 'home.solution.s4.title',
    description: 'home.solution.s4.description',
    classname: className
  },
  {
    index: 2,
    image: followImage
  },
  {
    index: 6,
    title: 'home.solution.s5.title',
    description: 'home.solution.s5.description',
    classname: className
  }
];

const Solution = () => (
  <div className="solution">
    <div className="container">
      <h2 className="h2--display solution__description">
        <FormattedMessage id="home.solution.title" />
      </h2>
      
      <StepsList
        steps={STEPS}
      />

    </div>
  </div>
);

export default Solution;
