import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';
import Pill from '@youship/components/objects/pill';

import './styles.scss';

const HomeGrow = () => {
  const intl = useIntl();

  return (
    <div className="home-grow">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-4 col-lg-4">
            <h6>
              <FormattedMessage id="home.grow.title" />
            </h6>
            <h2 className="h2--display home-grow__description">
              <FormattedMessage id="home.grow.description" />
            </h2>
          </div>
          <div className="col col-12 col-md-4 offset-md-0 col-lg-3 offset-lg-1">
            <div className="home-grow__item home-grow__item--margin">
              <Pill
                classNames="home-grow__pill"
                text={intl.formatMessage({ id: 'home.grow.transporters.title' })}
              />
              <div className="home-grow__text">
                <FormattedMessage id="home.grow.transporters.text" />
              </div>
              <Button
                context="primary"
                linkComponent={Link}
                linkProps={{ to: '/transporter' }}
                noBackground
                noPadding
                noShadow
                small
              >
                <FormattedMessage id="home.grow.transporters.link" />
              </Button>
            </div>
          </div>
          <div className="col col-12 col-md-4 offset-md-0 col-lg-3 offset-lg-1">
            <div className="home-grow__item">
              <Pill
                classNames="home-grow__pill"
                text={intl.formatMessage({ id: 'home.grow.partners.title' })}
              />
              <div className="home-grow__text">
                <FormattedMessage id="home.grow.partners.text" />
              </div>
              <Button
                context="primary"
                linkComponent={Link}
                linkProps={{ to: '/partners' }}
                noBackground
                noPadding
                noShadow
                small
              >
                <FormattedMessage id="home.grow.partners.link" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGrow;
